import { HeadFC } from "gatsby"
import React, { useEffect, useRef } from "react"
import { Swiper, SwiperSlide } from "swiper/react"
import { ProductCard } from "../components/product_card"
import { HeadSEO } from "../components/head"
import { IProduct } from "../dtos/IProduct.dto"
import { capitalizeFirstLetter } from "../utils/capitalizeFirstLetter"
import { Navigation } from "swiper/modules"

const normalizeString = (str: string) => {
  return str
    .normalize("NFD")
    .replace(/[\u0300-\u036f]/g, "")
    .replace(/[^a-zA-Z0-9\s]/g, "")
    .toLowerCase();
};

const normalize = (text: string) =>
  text.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();

const removeDuplicates = (items: IProduct[]) => {
  const seen = new Set();
  return items.filter((item) => {
    const key = item.id || normalize(item.title?.rendered || "");
    if (seen.has(key)) return false;
    seen.add(key);
    return true;
  });
};

const sortByNewFirst = (items: IProduct[]) => {
  return items.sort((a, b) => {
    const aIsNew = a.legenda_do_vdeo === "novo" ? -1 : 0;
    const bIsNew = b.legenda_do_vdeo === "novo" ? -1 : 0;
    return aIsNew - bIsNew;
  });
};

type PageProps = {
  location: {
    state: {
      categoryId: string
      productId: string
    }
  }
  pageContext: {
    categoryName: string;
    category: string;
    products: IProduct[]
  }
}

const OursProducts: React.FC<PageProps> = ({ pageContext }) => {
  const { products, category, categoryName } = pageContext

  const ourlinesRefs = useRef<{ [key: string]: HTMLDivElement | null }>({})

  const filterProductsByCategory = (categoryValues: string) => {
    const values = categoryValues.split(",").map((val) => normalize(val.trim())); 

    return sortByNewFirst(removeDuplicates(
      products.filter(product => {
        const title = normalize(product.title?.rendered || "");
        return values.some(value => title.includes(value)); 
      })
    ));
  };

  // Scroll para a categoria com base no hash do URL
  const scrollToCategory = (categoryValue: string) => {
    setTimeout(() => {
      const categorySection = document.getElementById(categoryValue)
      if (categorySection) {
        categorySection.scrollIntoView({ behavior: "smooth", block: "end" })
      }
    }, 1000)
  }

  useEffect(() => {
    const handleHashChange = () => {
      const hash = window.location.hash.replace("#", "")
      if (hash) {
        scrollToCategory(hash)
      }
    }

    window.addEventListener("hashchange", handleHashChange)
    handleHashChange()

    return () => window.removeEventListener("hashchange", handleHashChange)
  }, [])

  const categoryProducts = filterProductsByCategory(category)

  return (
    <div>
      <div className="container header">
        <div className="row">
          <div className="col-12 d-flex flex-column align-items-center py-5">
            <span className="h1 text-title text-center">{categoryName}</span>
          </div>

          {/* Seções de produtos */}
          <div
            id={normalizeString(categoryName)} 
            ref={(el) => (ourlinesRefs.current[category] = el)} 
            className="container mb-5"
          >
            <div
              className="text-title-small pb-4 mb-4 "
              style={{ borderBottom: "1px solid #cecece" }}
            >
              {/* {categoryName} */}
            </div>
            <Swiper
              className="destaques-linha"
              modules={[Navigation]}
              spaceBetween={20}
              slidesPerView={1.3}
              navigation
              breakpoints={{
                640: { slidesPerView: 2 },
                768: { slidesPerView: 3 },
                1024: { slidesPerView: 4 },
              }}
            >
              {categoryProducts.map((product) => (
                <SwiperSlide key={product.id}>
                  <div className="d-flex justify-content-center">
                    <ProductCard
                      activeRibbon={product.legenda_do_vdeo === "novo"}
                      ribbonText={product.legenda_do_vdeo === "novo" ? "Novidade" : ""}
                      product={product}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OursProducts

export const Head: HeadFC = () =>
  HeadSEO(
    capitalizeFirstLetter(
      `Conheça Nossas Linhas | Produtos Saudáveis - Verde Campo`,
    ),
    "Explore as diversas linhas de produtos da Verde Campo. Descubra opções saudáveis e saborosas para sua rotina. Veja agora!",
    "",
  )
