import {
  Box,
  Button,
  Flex,
  HStack,
  Image,
  Stack,
  Text,
  useBreakpointValue,
} from "@chakra-ui/react"
import { Link } from "gatsby"
import React from "react"
import { IProduct } from "../../dtos/IProduct.dto"
import { capitalizeFirstLetter } from "../../utils/capitalizeFirstLetter"
import { Claims } from "../claims"

interface ProductCardProps {
  isHovered?: boolean
  ribbonText?: string
  activeRibbon?: boolean
  product?: IProduct
  lineCard?: boolean
}

export const ProductCard = (
  props: ProductCardProps = {
    isHovered: false,
    ribbonText: "Mais vendido",
    activeRibbon: false,
    lineCard: false,
  },
) => {
  const [isHovered, setIsHovered] = React.useState(false)
  const isMobile = useBreakpointValue({ base: true, md: false })

  // Valores responsivos
  const imageSize = useBreakpointValue({ base: "160px", md: "220px" })
  const headingFontSize = useBreakpointValue({ base: "1rem", md: "1.25rem" })
  const subTextFontSize = useBreakpointValue({ base: "0.65rem", md: "0.75rem" })
  const categoryLetterSpacing = useBreakpointValue({ base: "0.1rem", md: "0.375rem" })
  const noOfLines = useBreakpointValue({ base: 3, md: 3 })
  const cardPadding = useBreakpointValue({ base: "1rem", md: "1.5rem" })
  const cardMaxWidth = useBreakpointValue({ base: "280px", md: "300px" })

  const { product, lineCard } = props

  return (
    <Stack
      className="card-product-line"
      width="100%"
      maxW={cardMaxWidth}       // Limita a largura máxima do card
      mx="auto"                 // Centraliza o card horizontalmente
      spacing="1rem"
      border={isHovered ? "1px solid #ADBDB9" : "1px solid transparent"}
      borderRadius="1.5rem"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      transition="all 0.3s ease"
      position="relative"
      zIndex={10}
      _before={
        props.activeRibbon
          ? {
              position: "absolute",
              top: "2rem",
              left: "-0.5rem",
              content: '""',
              background: "#008C15",
              height: "28px",
              width: "28px",
              transform: "rotate(45deg)",
            }
          : {}
      }
      _after={
        props.activeRibbon
          ? {
              position: "absolute",
              content: `"${props.ribbonText}"`,
              top: "11px",
              left: "-14px",
              padding: "0.5rem",
              width: "10rem",
              background: "#008C15",
              color: "white",
              textAlign: "center",
              boxShadow: "4px 4px 15px rgba(26, 35, 126, 0.2)",
              zIndex: 13,
              borderRadius: "0 1.68138rem 1.68138rem 0.5rem",
              fontSize: subTextFontSize,
            }
          : {}
      }
    >
      <Stack
        background="#F2F4F3"
        className="card-inside-line"
        zIndex={11}
        py={cardPadding}
        borderRadius="1.5rem"
        spacing="1rem"
        alignItems="center"
        justifyContent="center"
      >
        <Link
          to={`/produtos/${product?.slug}`}
          aria-label={`Detalhes do produto ${product?.title?.rendered}`}
        >
          <Image
            src={
              product?.miniatura_da_listagem?.guid ??
              "https://via.placeholder.com/250"
            }
            alt={product?.title?.rendered || "Imagem do produto"}
            borderRadius="1rem"
            objectFit="contain"
            width={imageSize}
            height={imageSize}
          />
        </Link>

        <Stack alignItems="center" justifyContent="center" spacing="0.5rem">
          <Flex
            alignItems="center"
            justifyContent="center"
            flexDirection="column"
            height="100%"
          >
            <Link
              to={`/produtos/${product?.slug}`}
              aria-label={`Detalhes do produto ${product?.title?.rendered}`}
            >
              <Text
                fontSize={subTextFontSize}
                fontWeight="800"
                lineHeight="150%"
                letterSpacing={categoryLetterSpacing}
                textAlign="center"
                color="#00754A"
                mb="0.25rem"
                textTransform="lowercase"
              >
                {product?.["produto_-_linha_2"] ?? "Categoria"}
              </Text>
              <Text
                as="h3"
                fontSize={headingFontSize}
                fontWeight="800"
                lineHeight="125%"
                color="#044F32"
                textAlign="center"
                textTransform="capitalize"
                noOfLines={noOfLines}
                padding="0px 2%"
              >
                {capitalizeFirstLetter(
                  product?.title?.rendered.replace(/lacfree/gi, "LACFREE"),
                )}
              </Text>
            </Link>
          </Flex>
          <HStack alignItems="center" justifyContent="space-around" width="100%">
            <Box className="group-claims">
              <Claims product={product} />
            </Box>
          </HStack>
        </Stack>

        {lineCard && (
          <Box
            className="mb-3 px-3 py-1 text-muted small text-product"
            style={{
              display: "-webkit-box",
              WebkitLineClamp: noOfLines,
              WebkitBoxOrient: "vertical",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              color: "#00615c",
            }}
            dangerouslySetInnerHTML={{
              __html: product?.descriocao || "",
            }}
          />
        )}

        <Button
          as={Link}
          to={`/produtos/${product?.slug}`}
          background="#025F1D"
          color="#fff"
          _hover={{ background: "#025f1cc5", color: "#ffffff" }}
          borderRadius="12.5rem"
          transition="all 0.3s ease"
          opacity={isHovered || isMobile ? 1 : 0}
          mt="0.5rem"
          aria-label={`Ver mais detalhes do produto ${product?.title?.rendered}`}
          size="md" // Deixa o botão um pouco menor
        >
          Ver mais detalhes
        </Button>
      </Stack>
    </Stack>
  )
}
